import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useAppSelector, useAppDispatch } from "@/hooks";
import {
  fetchAllCases,
  setSelectedCase,
  getDocumentCategories,
  selectCasesState,
  CasesPayload,
  getCasePrivicyLevels,
  getDocumentPrivicyLevels,
  getIndustries,
  getMethodologies,
} from "@/redux/casesSlice";
import { columns } from "./Table.data";
import Table from "../Table";
import { ICase, ITableFilter, IData } from "@/redux/store.types";
import { camelCaseToWords } from "../../utils";
import { motion } from "framer-motion";
import { ANNIMATION_SPEED } from "../../constants";

const MyCasesTableWrapper = () => {
  const appDispatch = useAppDispatch();
  const state = useAppSelector<IData<ICase>>(selectCasesState);
  const { data, pagination, isLoading } = state;
  const [selectedColumn, setSelectedColumn] = useState<number | string>(1);
  const [searchString, setSearchString] = useState<string>("");
  const [thunk, setThunk] = useState<any>(null);

  const [params, setParams] = useState<CasesPayload>({
    skip: 0,
    top: 20,
    sort: "",
    include: "*",
  });

  const onSelectCase = (data: ICase) => {
    appDispatch(setSelectedCase(data));
  };

  const onChangeFilters = (filter: ITableFilter[]) => {
    const args = { ...params };
    if (filter[0].value !== "") {
      args["filter"] = { items: filter };
    } else {
      delete args["filter"];
    }
    setParams(args);
  };

  const onFilterTable = () => {
    thunk && thunk.abort();
    setThunk(null);
    const args = { ...params };
    if (searchString === "" && params.filter) {
      delete args.filter;
    } else if (searchString !== "") {
      args["filter"] = {
        items: [
          {
            field: columns[Number(selectedColumn)].field,
            operator: "contains",
            value: searchString,
          },
        ],
      };
    }
    setParams(args);
  };

  const onSortChange = (sort: { sort: string }) => {
    const args = { ...params };
    if (sort.sort) {
      args.sort = sort.sort;
    } else {
      args.sort = "";
    }
    setParams(args);
  };

  const onPaginationChange = (pagination: number) => {
    const args = { ...params };
    args.skip = pagination;
    setParams(args);
  };

  useEffect(() => {
    const cancable = appDispatch(fetchAllCases(params));
    setThunk(cancable);
  }, [params, appDispatch]);

  useEffect(() => {
    appDispatch(getDocumentCategories());
    appDispatch(getCasePrivicyLevels());
    appDispatch(getDocumentPrivicyLevels());
    appDispatch(getIndustries());
    appDispatch(getMethodologies());
  }, []);

  const isDisabled = () => {
    if (searchString === "") {
      if (params.filter) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  const listItems = columns.filter((item: any, index) => {
    if (item.headerName !== "Status") {
      item.id = index;
      return item;
    }
  });

  return (
    <>
      <Typography variant="h5">My Cases</Typography>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <TextField
          label="Search"
          sx={{ minWidth: 300 }}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && searchString !== "") {
              onFilterTable();
            }
          }}
          value={searchString}
        />

        <Select
          variant="outlined"
          sx={{ border: "1px solid #ccc", width: 200 }}
          data-testid="column-selector"
          value={selectedColumn}
          onChange={(e: any) => {
            setSelectedColumn(e.target.value);
          }}
        >
          {listItems.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.headerName}
            </MenuItem>
          ))}
        </Select>
        <Button
          size="small"
          variant="contained"
          disableElevation
          onClick={onFilterTable}
          disabled={isDisabled()}
        >
          Filter
        </Button>
        <Button
          variant="text"
          onClick={() => {
            thunk && thunk.abort();
            setThunk(null);
            const args = { ...params };
            delete args.filter;
            setParams(args);
            setSearchString("");
            setSelectedColumn(1);
          }}
          disabled={searchString === "" || isLoading || !params.filter}
        >
          Clear
        </Button>
      </Box>

      <Table
        height={800}
        data={data}
        columns={columns}
        totalPages={pagination.total}
        pageSize={pagination.top}
        pageNumber={pagination.skip / pagination.top}
        onSelectRow={onSelectCase}
        isLoading={isLoading}
        onFilterChange={onChangeFilters}
        onSortChange={onSortChange}
        onPaginationChange={onPaginationChange}
      />
    </>
  );
};

export default MyCasesTableWrapper;
