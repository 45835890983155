import React, { useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import MSALUnauthenticatedTemplate from "@/templates/MSALUnauthenticatedTemplate";

import axiosInstance, { acquireToken } from "../../axiosConfig";

export const MSALAuthentication = ({ children }) => {
  const { accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    if (account && inProgress === InteractionStatus.None) {
      acquireToken()
        .then((token: string | undefined) => {
          if (token) {
            sessionStorage.setItem(
              "msalAccessToken",
              token.replace("Bearer ", "")
            );
            axiosInstance.defaults.headers.common["Authorization"] = token;
          } else {
            console.warn("No token received.");
          }
        })
        .catch((error) => {
          console.error("Error acquiring token:", error);
        });
    }
  }, [account, inProgress]);

  return (
    <>
      <AuthenticatedTemplate>
        <>{children}</>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <MSALUnauthenticatedTemplate />
      </UnauthenticatedTemplate>
    </>
  );
};

export default MSALAuthentication;
