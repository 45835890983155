import axios from "axios";

import { loginRequest, msalInstance } from "./authConfig";

export async function acquireToken() {
  try {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length === 0) {
      console.warn("No user account found. Redirecting to login.");
      await msalInstance.loginRedirect(loginRequest);
      throw new Error("User not logged in. Redirecting to login.");
    }

    const silentRequest = {
      ...loginRequest,
      account: accounts[0],
    };

    const tokenResponse = await msalInstance.acquireTokenSilent(silentRequest);
    return `Bearer ${tokenResponse.idToken}`;
  } catch (error) {
    if (
      [
        "login_required",
        "monitor_window_timeout",
        "interaction_required",
        "invalid_grant",
        "user_cancelled",
        "popup_window_error",
      ].includes(error.errorCode)
    ) {
      msalInstance.acquireTokenRedirect(loginRequest);
    } else {
      console.error("Error acquiring token silently:", error);
      throw error;
    }
  }
}

const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_APP_API}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      config.headers["Authorization"] = await acquireToken();
    } catch (error) {
      return Promise.reject(error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
